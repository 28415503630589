<template>
  <div class="contain" v-loading="loading">
    <div class="contain-top" @click="back">
      <img src="@/assets/icon_left.png" alt="" />
      <span>返回答题记录</span>
    </div>
    <div class="big" v-if="details.teacher_question_bank.type == 1">
      <div class="contain-center">
        <div class="contain-center-top">单选题</div>
        <div
          class="contain-center-title"
          v-html="details.teacher_question_bank.title"
        ></div>
        <div class="contain-center-check">
          <div class="contain-center-check-left">
            <div
              class="contain-center-check-box"
              v-for="(item, indexs) in details.teacher_question_bank.option"
              :key="indexs"
            >
              <img
                v-if="details.answer == item.name + indexs"
                src="@/assets/checked.png"
                alt=""
              />
              <img v-else src="@/assets/no_checked.png" alt="" />
              <span>{{ item.content }}</span>
            </div>
          </div>
          <img
            class="img"
            v-if="details.teacher_question_bank.answer == details.answer"
            src="@/assets/success.png"
            alt=""
          />
          <img class="img" v-else src="@/assets/error.png" alt="" />
        </div>
      </div>
      <div class="contain-bottom">
        <div class="contain-bottom-top">答案解析</div>
        <div class="contain-bottom-success">
          正确答案：<span>{{
            String.fromCharCode(details.teacher_question_bank.answer)
          }}</span>
        </div>
        <div class="contain-bottom-msg">
          {{ details.teacher_question_bank.analysis }}
        </div>
      </div>
    </div>
    <div class="big" v-else-if="details.teacher_question_bank.type == 2">
      <div class="contain-center">
        <div class="contain-center-top">多选题</div>
        <div
          class="contain-center-title"
          v-html="details.teacher_question_bank.title"
        ></div>
        <div class="contain-center-check">
          <div class="contain-center-check-left">
            <div
              class="contain-center-check-box"
              v-for="(item, indexs) in details.teacher_question_bank.option"
              :key="indexs"
            >
              {{ String.fromCharCode(item.name) }}&nbsp;&nbsp;
              <img v-if="item.isanswer" src="@/assets/checked.png" alt="" />
              <img v-else src="@/assets/no_checked.png" alt="" />
              <span>{{ item.content }}</span>
            </div>
          </div>
          <img
            class="img"
            v-if="details.answer == details.teacher_question_bank.answer"
            src="@/assets/success.png"
            alt=""
          />
          <img class="img" v-else src="@/assets/error.png" alt="" />
        </div>
      </div>
      <div class="contain-bottom">
        <div class="contain-bottom-top">答案解析</div>
        <div class="contain-bottom-success">
          正确答案：<span
            v-for="item in details.teacher_question_bank.answer.split(',')"
            :key="item"
          >
            {{ String.fromCharCode(item) }}
          </span>
        </div>
        <div class="contain-bottom-msg">
          {{ details.teacher_question_bank.analysis }}
        </div>
      </div>
    </div>
    <div class="big" v-else-if="details.teacher_question_bank.type == 3">
      <div class="contain-center">
        <div class="contain-center-top">填空题</div>
        <div
          class="contain-center-title"
          v-html="details.teacher_question_bank.title"
        ></div>
        <div class="contain-center-content">
          <div class="contain-center-content-box">
            <span>我的回答：</span>{{ details.answer }}
          </div>
        </div>
      </div>
      <div class="contain-bottom">
        <div class="contain-bottom-top">答案解析</div>
        <div class="contain-bottom-success">
          正确答案：<span>{{ details.teacher_question_bank.answer }}</span>
        </div>
        <div class="contain-bottom-msg">
          {{ details.teacher_question_bank.analysis }}
        </div>
      </div>
    </div>
    <div class="big" v-else-if="details.teacher_question_bank.type == 4">
      <div class="contain-center">
        <div class="contain-center-top">简答题</div>
        <div
          class="contain-center-title"
          v-html="details.teacher_question_bank.title"
        ></div>
        <div class="textarea">
          <div class="contain-center-content-box">
            <span>我的回答：</span>{{ details.answer }}
          </div>
        </div>
      </div>
      <div class="contain-bottom">
        <div class="contain-bottom-top">答案解析</div>
        <div class="contain-bottom-success">参考答案</div>
        <div class="contain-bottom-msg">
          {{ details.teacher_question_bank.answer }}
        </div>
      </div>
    </div>
    <div class="big" v-else-if="details.teacher_question_bank.type == 5">
      <div class="contain-center">
        <div class="contain-center-top">判断题</div>
        <div
          class="contain-center-title"
          v-html="details.teacher_question_bank.title"
        ></div>
        <div class="contain-center-check">
          <div
            class="contain-center-check-left"
            style="display: flex; margin-top: 30px"
          >
            <div
              class="contain-center-checkbox"
              :class="
                details.teacher_question_bank.answer == '正确'
                  ? 'contain-center-checkbox-check'
                  : ''
              "
              style="margin-right: 20px"
              @click="checkJudge(1, '正确')"
            >
              <i class="el-icon-success" style="margin-right: 10px"></i> 正确
            </div>
            <div
              class="contain-center-checkbox"
              :class="
                details.teacher_question_bank.answer == '错误'
                  ? 'contain-center-checkbox-check'
                  : ''
              "
              @click="checkJudge(2, '错误')"
            >
              <i class="el-icon-error" style="margin-right: 10px"></i> 错误
            </div>
          </div>
          <img
            class="img"
            v-if="details.teacher_question_bank.answer == details.answer"
            src="@/assets/success.png"
            alt=""
          />
          <img class="img" v-else src="@/assets/error.png" alt="" />
        </div>
      </div>
      <div class="contain-bottom">
        <div class="contain-bottom-top">答案解析</div>
        <div class="contain-bottom-success">
          正确答案：<span>{{ details.answer }}</span>
        </div>
        <div class="contain-bottom-msg">{{ details.analysis }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import request from "@/utils/request";
export default {
  data() {
    return {
      loading: true,
      show: false,
      active: 2,
      textarea: "",
      type: 0,
      form: {
        teacher_question_bank_type_id: "",
        is_last_start: "",
        is_random: "",
        limit: 100,
      },
      index: 0,
      list: [],
      answerList: [],
      total: 0,
      details: null,
      answer: {
        teacher_question_bank_id: "",
        answer: "",
      },
      isok: 2,
      length: 0,
      value: [],
      btnTitle: "提交",
    };
  },
  created() {
    this.getSubjectList();
  },
  methods: {
    back() {
      this.$router.go(-1);
    },

    getSubjectList() {
      this.loading = true;
      request({
        url: "/api/teacherend/teacherQuestionBank/logDetail",
        method: "get",
        params: { id: this.$route.query.id },
      }).then((response) => {
        this.details = response.data;
        this.details.teacher_question_bank = JSON.parse(
          response.data.teacher_question_bank_snap
        );
        this.loading = false;
        if (this.details.teacher_question_bank.type == 1) {
          this.details.teacher_question_bank.option = JSON.parse(
            this.details.teacher_question_bank.option
          );
          this.active = this.details.teacher_question_bank.option.findIndex(
            (item) => {
              return item.name == this.details.answer;
            }
          );
        } else if (this.details.teacher_question_bank.type == 2) {
          //字符串装换对象
          let option = JSON.parse(this.details.teacher_question_bank.option);
          this.details.answerList = this.details.answer.split(",");
          option.forEach((op, i) => {
            op.name = op.name + i;
            op.isanswer = false;
            for (
              let index = 0;
              index < this.details.answerList.length;
              index++
            ) {
              const element = this.details.answerList[index];
              if (element == op.name) {
                op.isanswer = true;
              }
            }
            console.log(op);
          });
          //自己选择的选项

          this.details.teacher_question_bank.option = option;
        } else if (this.details.teacher_question_bank.type == 3) {
          this.details.teacher_question_bank.title =
            this.details.teacher_question_bank.title.replaceAll(
              "{input}",
              "_____"
            );
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.contain {
  min-height: calc(100vh - 160px);
  background: #ffffff;
  border-radius: 9px;
  .contain-top {
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 20px;
    padding-left: 50px;
    img {
      width: 15px;
      height: 15px;
    }
    span {
      margin-left: 10px;
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #506176;
    }
  }
  .big {
    padding: 20px 50px;
    padding-top: 0;
    .contain-center {
      margin-top: 46px;
      padding-bottom: 30px;
      border-bottom: 1px solid #f9fafd;
      .contain-center-top {
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #506176;
        span {
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #506176;
          margin-left: 20px;
        }
      }
      .contain-center-title {
        margin-top: 20px;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 34px;
      }
      .contain-center-content {
        margin-top: 30px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .img {
          width: 120px;
          height: 102px;
          margin-left: 100px;
        }
        .contain-center-content-box {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 20px;
          span {
            margin-right: 10px;
          }
        }
      }
      .textarea {
        margin-top: 36px;
        margin-bottom: 80px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .img-box {
          .img {
            width: 120px;
            height: 102px;
            margin-left: 100px;
          }
        }
      }
      .contain-center-check {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .img {
          width: 120px;
          height: 102px;
          margin-left: 140px;
        }
        .contain-center-check-left {
          .contain-center-check-box {
            cursor: pointer;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 25px 0;
            img {
              width: 20px;
              height: 20px;
              border-radius: 50%;
            }
            span {
              margin-left: 17px;
              font-size: 20px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
            }
          }
          .contain-center-checkbox {
            width: 105px;
            height: 44px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #cccccc;
            border-radius: 8px;
            color: #999999;
            font-size: 20px;
            cursor: pointer;
          }
          .contain-center-checkbox-check {
            border-color: red;
            color: red;
          }
        }
      }
      .contain-center-btn {
        margin-top: 45px;
      }
    }
    .contain-bottom {
      margin-top: 20px;
      .contain-bottom-top {
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #506176;
      }
      .contain-bottom-success {
        margin-top: 15px;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        span {
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #f24342;
        }
      }
      .contain-bottom-msg {
        margin-top: 12px;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 31px;
      }
    }
  }
}
</style>